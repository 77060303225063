export default function Map() {
    const $popup = $("#mypopup");
    const $liAll = $("#mapNavigation li[data-map]");
    const $svg = $("#map__wrapper svg");
    const $gAll = $("g[data-map]", $svg);

    $("[data-map]").on("mouseenter mouseleave", function(ev) {
      const id = $(this).data("map");
      const $li = $(`#mapNavigation li[data-map="${id}"]`);
      const $g = $(`g[data-map="${id}"]`, $svg);
      const desc = $li.text();

      $liAll.add($gAll).removeClass("selected");

      if (ev.type === "mouseenter") {
        
        $li.add($g).addClass("selected");
        const {width, height} = $svg.prop("viewBox").baseVal;
        const {x, y} = $g[0].getBBox();

        $popup
          .show()
          .text(desc)
          .css({
            left: `${x / width * 100}%`,
            top: `${y / height * 100}%`
          });
        
      } else {
        
        $li.add($g).removeClass("selected");
        $popup.hide().text("");
        
      }
    });
    
};