export default function Carousel() {

    $('#gallery').each(function (index, sliderWrap) {
        var $slider = $(sliderWrap).find('.carousel__slides');
        $slider.slick({
            dots: true,
            appendDots: $('.slick-slider-dots'),
            arrows: false,
            speed: 700,
            infinite: true,
            centerMode: true,
            centerPadding: '15%',
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: 6000,
            draggable: true,
            touchThreshold: 200,
            pauseOnHover: false,
            responsive: [
            {
              breakpoint: 1024,
              settings: {
                centerPadding: '10%',
              }
            },
            {
              breakpoint: 768,
              settings: {
                centerPadding: '0%',
              }
            },
            {
              breakpoint: 576,
              settings: {
                centerPadding: '0%',
              }
            }
          ]
        });

        // Custom carousel nav
        $('.prev').click(function (e) {
            e.preventDefault();
            $(this).parent().find($slider).slick('slickPrev');
        });

        $('.next').click(function (e) {
            e.preventDefault();
            $(this).parent().find($slider).slick('slickNext');
        });

    }); 
};  