export default function Inview() {
        
    $.fn.isInViewport = function () {
        let elementTop = $(this).offset().top;
        let elementBottom = elementTop + $(this).outerHeight();

        let viewportTop = $(window).scrollTop();
        let viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    $('.js-fade-in-up').each(function(i, el){
        if ($(this).isInViewport()) {
            $(this).addClass('o-fade-in-up');
        } 
    })

    $('.js-fade-in').each(function(i, el){
        if ($(this).isInViewport()) {
            $(this).addClass('o-fade-in');
        } 
    })

    $('.js-stroke').each(function(i, el){
        if ($(this).isInViewport()) {
            $(this).addClass('o-stroke-reveal');
        } 
    })

    $('.js-tick').each(function(i, el){
        if ($(this).isInViewport()) {
            $(this).addClass('o-tick');
        } 
    })

    $('#preload').each(function(i, el){
        if ($(this).isInViewport()) {
            $(this).addClass('is-active');
        } 
    })

};

