import $ from 'jquery';
window.jQuery = $;

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import '@popperjs/core';
import 'bootstrap';

import 'slick-carousel';


import CookieBanner from './components/cookie-banner';
import InView from './components/inviewport';
import Navigation from './components/navigation';
import Carousel from './components/carousel';
import Type from './components/type';
import Map from './components/map';

jQuery(document).ready(() => {
    CookieBanner();
    InView();
    Navigation();
    Carousel();
    Type();
    Map();

    $("#mis-rep-btn").on('click', function(e) {
        e.preventDefault();
        $('body').addClass('no-scroll');
        $('#mis-act').fadeIn(200);
    });

    $(".cookie-btn").on('click', function(e) {
        e.preventDefault();
        $('body').addClass('no-scroll');
        $('#cookie-policy').fadeIn(200);
    });

    $(".close").on('click', function(e) {
        e.preventDefault();
        $('body').removeClass('no-scroll');
        $('.f-popup').fadeOut(200);
    });

});


$(window).scroll(function () {
    InView();
});


if (sessionStorage.getItem('splash') !== 'true') {
    setTimeout(function () {
        $('#preload').addClass('is-close');
    }, 2500);
    setTimeout(function () {
        $('body').addClass('loaded');
        $('#preload').addClass('hide');
        $('.hero').fadeIn(100);
    }, 4500);

    setTimeout(function () {
        $('#preload').remove();
    }, 5000);

    sessionStorage.setItem('splash', 'true');

} else {
    $('body').addClass('loaded');
    $('#preload').remove();
}