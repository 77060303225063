import SplitType from 'split-type';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger); 

export default function Type() {

    
    const body = document.body;
    const anilines = gsap.utils.toArray(".js-words");

    anilines.forEach((target) => {
      let animation = gsap.timeline({
        scrollTrigger: {
          trigger: target,
          markers: false,
          toggleActions: "play none none none"
        }
      });
      let split = new SplitType(target.querySelector(".js-words h4"), { types: "words", tagName: "span" });
      animation.from(split.words, {
        opacity: 0,
        y: 20,
        delay: 0.5,
        duration: 0.5,
        ease: "back.out(1)",
        stagger: {
          from: "start", 
          each: 0.1
        }
      });
    });



    const anichars = gsap.utils.toArray(".js-chars");

    anichars.forEach((target) => {
      let animation = gsap.timeline({
        scrollTrigger: {
          trigger: target,
          markers: false,
          toggleActions: "play none none none"
        }
      });
      let split = new SplitType(target.querySelector(".js-chars .js-animate"), { types: "chars", tagName: "span" });
      animation.from(split.chars, {
        opacity: 0,
        y: 50,
        delay: 0.7,
        duration: 0.2,
        ease: "back.out(1)",
        stagger: {
          from: "start", 
          each: 0.05
        }
      });
    }); 

};
